import React, { useRef } from "react";
import { useSelector } from "react-redux";
import SectionTitle from "../../../common/shared/SectionTitle";
import ReactToPrint from "react-to-print";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import logo from "../../../assets/img/gba-logo.png";
import moment from "moment";
import { useLocation } from "react-router-dom";
import DuesPayment from "../components/DuesPayment";
import ManualPaymentButton from "../components/ManualPaymentButton";
import { normalDues } from "../../../redux/actions/dues_action";
import UpdatePaymentButton from "../components/UpdatePaymentButton";
import RegionDuesButton from "./RegionDuesButton";

function DuesInvoice() {
  const location = useLocation();

  let cardBodyRef = useRef();
  const user = useSelector((state) => state.auth?.userData);

  const duesData = location.state?.formData.data;

  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <SectionTitle title="Dues Invoice" description="" children={[]} />

      <div className="flex items-center justify-center gap-3 mb-8">
        <div>
          <ReactToPrint
            trigger={() => <Button>Print Invoice</Button>}
            content={() => cardBodyRef}
            removeAfterPrint={false}
          />
        </div>
        {duesData.title === "dues" ||
        duesData.title === "women" ||
        duesData.title === "forum" ? (
          <>
            <DuesPayment
              data={duesData.data}
              amount={duesData.data.amount}
              year={duesData.data?.year}
              id={duesData?.data?.id}
              post_type="dues"
            />
            <ManualPaymentButton
              amount={duesData?.data?.amount}
              year={duesData?.data?.year}
              id={duesData?.data?.id}
              post_type="dues"
              apiFxn={normalDues}
            />
          </>
        ) : duesData.title === "outstanding" ? (
          <UpdatePaymentButton
            data={duesData.data}
            amount={duesData.amount}
            id={duesData.data.id}
          />
        ) : (
          <>
            <RegionDuesButton
              data={duesData.data}
              amount={duesData.region.amount}
              year={duesData?.data?.year}
              id={duesData?.data?.id}
              post_type="regdues"
              sub_type={duesData.region?.name}
            />
            <ManualPaymentButton
              amount={duesData.region?.amount}
              year={duesData?.data?.year}
              id={duesData?.data?.id}
              post_type="regdues"
              apiFxn={normalDues}
              name={duesData.region.name}
              check={duesData?.data?.receipts?.some(
                (item) =>
                  item.post_type === "regdues" &&
                  item.sub_type === duesData.region.name &&
                  duesData.status === 0
              )}
            />
          </>
        )}
      </div>

      <div className="flex flex-col items-center justify-center mb-8">
        <Typography variant="h5" color="red" className="text-center">
          Electronic Payment attracts a payment charge of 2% (GH¢
          {duesData.title === "dues"
            ? parseFloat((duesData.data.amount * 0.02).toFixed(2))
            : duesData.title === "outstanding"
            ? parseFloat((duesData.amount * 0.02).toFixed(2))
            : parseFloat((duesData.region.amount * 0.02).toFixed(2))}
          )
        </Typography>
        <Typography variant="h5" color="red" className="text-center">
          Manual Payment attracts a payment charge of 3% (GH¢
          {duesData.title === "dues"
            ? parseFloat(duesData.data.amount * 0.03).toFixed(2)
            : duesData.title === "outstanding"
            ? parseFloat(duesData.amount * 0.03).toFixed(2)
            : parseFloat(duesData.region.amount * 0.03).toFixed(2)}
          )
        </Typography>
      </div>

      <Card>
        <CardBody ref={(el) => (cardBodyRef = el)}>
          <img src={logo} alt="logo" className="h-20" />

          <div className="mt-5 pl-3 flex flex-col md:flex-row gap-8 justify-between">
            <div>
              <Typography variant="h5">Ghana Bar Association</Typography>
              <Typography variant="paragraph">
                H/NO. 5, 2nd Ave. State House Road,
              </Typography>
              <Typography variant="paragraph">
                South-East Ridge, Adjacent to
              </Typography>
              <Typography variant="paragraph">
                Ghana International Press Centre,
              </Typography>
              <Typography variant="paragraph">Accra</Typography>
            </div>

            <div>
              <Typography variant="h5">
                Billed to:{" "}
                {user.title + " " + user.first_name + " " + user.last_name}
              </Typography>
              <Typography variant="paragraph">
                Email Address: {user.email}
              </Typography>
              <Typography variant="paragraph">Phone #: {user.phone}</Typography>

              <div className="mt-20">
                <Typography variant="h6" color="gray">
                  Invoice Date
                </Typography>
                <Typography variant="h4">
                  {moment().format("MMMM Do, YYYY")}
                </Typography>
              </div>
            </div>
          </div>

          <div className="mt-24   overflow-x-auto">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      Description
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      Qty
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      Amount
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {duesData.data.name} -{" "}
                      {duesData.title === "dues"
                        ? "National"
                        : duesData.title === "outstanding"
                        ? "Outstanding"
                        : duesData.title === "women"
                        ? "Women Dues"
                        : duesData.title === "in-house_forum"
                        ? "In-House Forum"
                        : duesData.title}
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      1
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {duesData.title === "dues"
                        ? duesData.data.amount
                        : duesData.title === "outstanding"
                        ? duesData.amount
                        : duesData.region.amount}
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-semibold"
                    >
                      Total
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      GH¢
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-semibold"
                    >
                      {duesData.title === "dues"
                        ? duesData.data.amount
                        : duesData.title === "outstanding"
                        ? duesData.amount
                        : duesData.region.amount}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-40">
            <Typography variant="h4">Thank you!</Typography>
            <Typography variant="paragraph">
              If you encounter any issue related to the invoice you can contact
              us at:
            </Typography>

            <Typography variant="paragraph">
              email: <span className="font-semibold">info@ghanabar.org</span>
            </Typography>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default DuesInvoice;
