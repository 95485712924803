import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDuesReceipt } from "../../../redux/actions/receipt_action";
import SectionTitle from "../../../common/shared/SectionTitle";
import AlertMessage from "../../../common/actions/AlertMessage";
import Loader from "../../../common/loader/Loader";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import logo from "../../../assets/img/gba-logo.png";
import padWithZero from "../../../utils/padZero";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

function DuesReceipt() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  let cardBodyRef = useRef();

  const user = useSelector((state) => state.auth?.userData);
  const { data, receiptError } = useSelector((state) => state.payment);

  const handlePrint = useReactToPrint({
    content: () => cardBodyRef,
    removeAfterPrint: false,
  });

  useEffect(() => {
    dispatch(getDuesReceipt(id)).finally(() => setLoading(false));
  }, [dispatch, id]);

  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <SectionTitle title="Receipt" description="" />

      {receiptError && <AlertMessage type="error" message={receiptError} />}

      {loading ? (
        <Loader />
      ) : data ? (
        <Card>
          <CardBody ref={(el) => (cardBodyRef = el)}>
            <img src={logo} alt="logo" className="h-20" />

            <div className="mt-5 pl-3 flex flex-col md:flex-row gap-8 justify-between">
              <div>
                <Typography variant="h5">Ghana Bar Association</Typography>
                <Typography variant="paragraph">
                  H/NO. 5, 2nd Ave. State House Road,
                </Typography>
                <Typography variant="paragraph">
                  South-East Ridge, Adjacent to
                </Typography>
                <Typography variant="paragraph">
                  Ghana International Press Centre,
                </Typography>
                <Typography variant="paragraph">Accra</Typography>

                <div className="mt-5">
                  <Typography variant="h6" color="gray">
                    Receipt Reference
                  </Typography>
                  <Typography variant="h4">
                    {padWithZero(data?.receipt?.serial_number)}
                  </Typography>
                </div>
              </div>

              <div>
                <Typography variant="h5">
                  Billed to:{" "}
                  {user.title + " " + user.first_name + " " + user.last_name}
                </Typography>
                <Typography variant="paragraph">
                  Email Address: {user.email}
                </Typography>
                <Typography variant="paragraph">
                  Phone #: {user.phone}
                </Typography>

                <div className="mt-20">
                  <Typography variant="h6" color="gray">
                    Receipt Date
                  </Typography>
                  <Typography variant="h4">
                    {moment(data?.receipt?.date_paid).format("MMMM Do, YYYY")}
                  </Typography>
                </div>
              </div>
            </div>

            <div className="mt-24 overflow-x-auto">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        Description
                      </Typography>
                    </th>
                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        Qty
                      </Typography>
                    </th>
                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        Amount
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-4 border-b border-blue-gray-50">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {data.receipt.product.name} -{" "}
                        {data.receipt.post_type === "dues"
                          ? "National"
                          : data.receipt.post_type === "inhouse_forum"
                          ? "In-House Forum"
                          : data.receipt.post_type === "women"
                          ? "Women Forum"
                          : data.receipt.post_type
                          ? data.receipt.sub_type
                          : "Regional"}
                      </Typography>
                    </td>

                    <td className="p-4 border-b border-blue-gray-50">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        1
                      </Typography>
                    </td>

                    <td className="p-4 border-b border-blue-gray-50">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {data.receipt.amount ? data.receipt.amount : 0}
                      </Typography>
                    </td>
                  </tr>

                  {data.receipt.method === 1 && (
                    <tr>
                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          Payment Processing - Charges
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          1
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {data?.receipt?.amount
                            ? data?.receipt?.amount * 0.02
                            : 0}
                        </Typography>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td className="p-4 border-b border-blue-gray-50">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-semibold"
                      >
                        Total
                      </Typography>
                    </td>
                    <td className="p-4 border-b border-blue-gray-50"></td>
                    <td className="p-4 border-b border-blue-gray-50">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-semibold"
                      >
                        GH¢
                        {data?.receipt?.amount +
                          (data.receipt.method === 1
                            ? data?.receipt?.amount * 0.02
                            : 0)}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mt-40">
              <Typography variant="h4">Thank you!</Typography>
              <Typography variant="paragraph">
                If you encounter any issue related to the receipt you can
                contact us at:
              </Typography>

              <Typography variant="paragraph">
                email: <span className="font-semibold">info@ghanabar.org</span>
              </Typography>
            </div>
          </CardBody>
          <CardFooter className="flex justify-center">
            <Button onClick={() => handlePrint()}>Print Receipt</Button>
          </CardFooter>
        </Card>
      ) : null}
    </div>
  );
}

export default DuesReceipt;
