import {
  Button,
  Dialog,
  DialogFooter,
  DialogHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmPayment from "../../../common/shared/ConfirmPayment";

function ManualPaymentButton({
  amount,
  year,
  id,
  post_type,
  check,
  apiFxn,
  name = null,
}) {
  const [manualLoading, setManualLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const dispatch = useDispatch();

  const handleOpen = () => setOpen(!open);
  const handleConfirm = () => setConfirm(!confirm);

  const handleManual = () => {
    setManualLoading(true);
    const formData = {
      amount,
      year,
      id,
      post_type,
      name,
    };

    dispatch(apiFxn(formData)).finally(() => {
      setManualLoading(false);
      setOpen(true);
    });
  };

  return (
    <>
      <Button
        onClick={handleConfirm}
        size="md"
        color="amber"
        disabled={manualLoading}
      >
        {manualLoading ? <Spinner /> : "Manual Pay"}
      </Button>

      {open && (
        <Dialog open={open} handleOpen={handleOpen}>
          <DialogHeader>
            <Typography variant="h5" color="blue-gray">
              Manual Payment Successfully Added
            </Typography>
          </DialogHeader>
          <DialogFooter>
            <Button variant="text" color="blue-gray" onClick={handleOpen}>
              Close
            </Button>
          </DialogFooter>
        </Dialog>
      )}

      {confirm && (
        <ConfirmPayment
          open={confirm}
          setOpen={setConfirm}
          title="Manual"
          amount={amount}
          loading={manualLoading}
          handleConfirm={handleManual}
          handleClose={handleConfirm}
        />
      )}
    </>
  );
}

export default ManualPaymentButton;
